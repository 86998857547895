<template>
  <div class="case-administrative">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="案件名称">
					<el-input v-model="formSearch.companyName" placeholder="请输入案件名称"></el-input>
				</el-form-item>
				<el-form-item label="立案时间">
					<el-date-picker v-model="formSearch.timeLA" value-format="yyyy-MM-dd" type="daterange" align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsLA"></el-date-picker>
				</el-form-item>
				<el-form-item label="报送地市" v-show="levelSession == 1">
					<el-select v-model="formSearch.cityId" clearable>
						<el-option v-for="(item,index) in formSearch.cityIdList" :key="index" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="案件类型">
					<el-select v-model="formSearch.eventStatus" clearable>
						<el-option label="新发案件" :value="0"></el-option>
						<el-option label="存量案件" :value="1"></el-option>
						<el-option label="零上报" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="报送时间">
					<el-date-picker v-model="formSearch.timeBS" value-format="yyyy-MM-dd" type="daterange" align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsBS"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box case-tabs-box" style="position: relative;">
			<el-card>
				<el-tabs v-model="formSearch.uploadStatus" @tab-click="handleClick">
					<el-tab-pane label="台账" name="0"></el-tab-pane>
					<el-tab-pane label="历史上报报表" name="1"></el-tab-pane>
				</el-tabs>
				<h4 class="title-h4" style="float: left;">案件总数：<span>{{totalItems}}</span> 个</h4>
				<el-table border v-loading="loadingFlag" :data="tableData" @sort-change='sortChange' :default-sort="{prop: 'updateTime', order: 'descending'}" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column fixed type="selection" width="30"></el-table-column>
					<el-table-column fixed type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column fixed prop="provinceName" label="地区" width="150" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column fixed prop="casename" label="案件名称" width="200" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="eventStatus" label="案件类型" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.eventStatus == 0">新发案件</span>
							<span v-if="scope.row.eventStatus == 1">存量案件</span>
							<span v-if="scope.row.eventStatus == 2">零上报</span>
						</template>
					</el-table-column>
					<el-table-column prop="jbxxWdfje" label="未清退金额(万元)" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxJzrs" label="未清退人数(人)" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="xzczLasj" label="立案时间" width="90" :show-overflow-tooltip="true" sortable="custom"></el-table-column>
					<el-table-column prop="jbxxSshy" label="行业领域" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxSshyqt" label="其他行业" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxJzqd" label="集资渠道" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="xzczFzczdxzjgssq" label="行政机关所在地" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="xzczFzczdxzjg" label="负责处置的行政机关" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxLjjzje" label="集资金额(万元)" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxLsjzrs" label="参与集资人数(人)" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="processCityName" label="报送地市" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="uploadTime" label="报送时间" width="90" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" width="90" :show-overflow-tooltip="true" sortable="custom"></el-table-column>
					<el-table-column prop="uploadStatus" label="案件状态" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.uploadStatus == 0">新增待上报</span>
							<span v-if="scope.row.uploadStatus == 1">已上报</span>
							<span v-if="scope.row.uploadStatus == 2">被驳回</span>
							<span v-if="scope.row.uploadStatus == 10">新增待上报</span>
							<span v-if="scope.row.uploadStatus == 11">下级上报</span>
							<span v-if="scope.row.uploadStatus == 12">已上报</span>
							<span v-if="scope.row.uploadStatus == 13">已驳回</span>
						</template>
					</el-table-column>
					<el-table-column prop="rejectReason" label="驳回原因" width="150" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column fixed="right" prop="" label="操作" width="80">
						<template slot-scope="scope">
							<i class="iconfont icon-bianji color-m" title="编辑" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
							<i class="iconfont icon-shangbao color-m" title="上报" v-show="scope.row.uploadStatus != 13" @click="handleReport(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
							<i class="iconfont icon-fanhui3 color-m" title="驳回" v-show="scope.row.uploadStatus == 11" @click="handleBack(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
				<el-button v-show="totalItems != 0" type="danger" size="mini" @click="handeleDelete" style="position: absolute;top: 25px;right: 90px;z-index: 999;">删除</el-button>
				<el-button v-show="formSearch.uploadStatus == 0" type="primary" size="mini" @click="handeleAdd" style="position: absolute;top: 25px;right: 20px;z-index: 999;">新增</el-button>
			</el-card>
		</div>
		
		<!-- 编辑   弹框 -->
		<el-dialog title="行政案件信息" :visible.sync="dialogVisibleBJ" width="80%" class="madal-box-anjian" :close-on-click-modal="false" @close="closeHandle">
			<el-button type="primary" size="mini" @click="zeroStatusHandle" v-show="ruleFormBJ.zeroStatusShow" style="float: right;margin-right: 20px;">{{ruleFormBJ.zeroStatusName}}</el-button>
			<div :style="ruleFormBJ.zeroStatus == 0 ? 'width: 100%;height: 600px;' : 'width: 100%;'">
				<el-scrollbar style="height: 100%;width:100%">
					<el-form :model="ruleFormBJ" :rules="rulesFormBJ" ref="ruleFormBJ" label-width="200px" class="demo-ruleForm">
						<h4 class="title-h4">基本信息</h4>
						<el-row :gutter="30">
							<el-col :span="24">
								<el-form-item>
									<span slot="label">案件名称<font style="color: #F56C6C;">*</font></span>
									<el-row :gutter="0" v-show="ruleFormBJ.zeroStatus == 0">
										<el-col :span="1" style="text-align: left;line-height: 34px;margin-bottom: 10px;color: #ccc;">企业：</el-col>
										<el-col :span="23" style="text-align: left;line-height: 30px;">	
											<el-tag
												v-for="(item,index) in ruleFormBJ.tagsListQY"
												:key="index"
												closable
												@close="handleCloseTagsQY(item)" @click="handleClickTagsQY(item,index)">
												{{item.companyName}}
												<!-- <el-input size="mini" v-model="item.companyName"></el-input> -->
											</el-tag>
											<i class="iconfont icon-tianjia color-m" @click="handleAddTags(1)" style="font-size: 18px;cursor: pointer;"></i>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormBJ.zeroStatus == 0">
										<el-col :span="1" style="text-align: left;line-height: 34px;margin-bottom: 10px;color: #ccc;">个人：</el-col>
										<el-col :span="23" style="text-align: left;line-height: 30px;">	
											<el-tag
												v-for="(item,index) in ruleFormBJ.tagsListGR"
												:key="index"
												closable
												@close="handleCloseTagsGR(item)" @click="handleClickTagsGR(item,index)">
												{{item.personName}}
												<!-- <el-input size="mini" v-model="item.personName"></el-input> -->
											</el-tag>
											<i class="iconfont icon-tianjia color-m" @click="handleAddTags(2)" style="font-size: 18px;cursor: pointer;"></i>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormBJ.zeroStatus == 0">
										<el-col :span="1" style="text-align: left;line-height: 34px;color: #ccc;">其他：</el-col>
										<el-col :span="23" style="text-align: left;line-height: 30px;">	
											<el-tag
												v-for="(item,index) in ruleFormBJ.tagsListQT"
												:key="index"
												closable
												@close="handleCloseTagsQT(item)" @click="handleClickTagsQT(item,index)">
												{{item.companyName}}
												<!-- <el-input size="mini" v-model="item.companyName"></el-input> -->
											</el-tag>
											<i class="iconfont icon-tianjia color-m" @click="handleAddTags(3)" style="font-size: 18px;cursor: pointer;"></i>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormBJ.zeroStatus == 1">
										<el-col :span="1" style="text-align: left;line-height: 43px;color: #ccc;">其他：</el-col>
										<el-col :span="11" style="text-align: left;">	
											<el-input v-model="ruleFormBJ.jbxxQtLsb"></el-input>
										</el-col>
									</el-row>
								</el-form-item>
							</el-col>
						</el-row>
						<div v-show="ruleFormBJ.zeroStatus == 0">
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item prop="jbxxSshy">
										<span slot="label">行业领域<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.jbxxSshy" @change="jbxxSshyChange">
											<el-option v-for="(item,index) in ruleFormBJ.industryList" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item prop="jbxxSshyqt" v-show="ruleFormBJ.jbxxSshy == '其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）'">
										<span slot="label">其他行业<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxSshyqt"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="集资渠道" prop="jbxxJzqd">
										<span slot="label">集资渠道<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.jbxxJzqd">
											<el-option v-for="(item,index) in ruleFormBJ.financingChannelsList" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item prop="eventStatus">
										<span slot="label">案件类型<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.eventStatus">
											<el-option label="新发案件" :value="0"></el-option>
											<el-option label="存量案件" :value="1"></el-option>
											<!-- <el-option label="零上报" :value="2"></el-option> -->
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="" prop="jbxxLjjzje">
										<span slot="label">集资金额<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxLjjzje"></el-input>
									</el-form-item>
									<el-form-item label="" prop="jbxxLsjzrs">
										<span slot="label">参与集资人数<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxLsjzrs"></el-input>
									</el-form-item>
									<el-form-item label="" prop="fzczdxzjgssq">
										<span slot="label">行政机关所在地<font style="color: #F56C6C;">*</font></span>
										<el-cascader v-model="ruleFormBJ.fzczdxzjgssq" :options="ruleFormBJ.quyuList" :props="ruleFormBJ.quyuProps" ref="quyuRef" change-on-select style="width: 100%;"></el-cascader>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="未清退金额" prop="jbxxWdfje">
										<span slot="label">未清退金额<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxWdfje"></el-input>
									</el-form-item>
									<el-form-item label="未清退人数" prop="jbxxJzrs">
										<span slot="label">未清退人数<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxJzrs"></el-input>
									</el-form-item>
									<el-form-item label="负责处置的行政机关所在地" prop="xzczFzczdxzjg">
										<span slot="label">负责处置的行政机关所在地<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.xzczFzczdxzjg"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<h4 class="title-h4">调查认定</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="" prop="xzczLasj">
										<span slot="label">立案时间<font style="color: #F56C6C;">*</font></span>
										<el-date-picker type="date" v-model="ruleFormBJ.xzczLasj" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
									</el-form-item>
									<el-form-item label="行政认定" prop="xzczXzrdjg">
										<el-select v-model="ruleFormBJ.xzczXzrdjg">
											<el-option label="不是非法集资" :value="0"></el-option>
											<el-option label="是非法集资" :value="1"></el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="限制出境" prop="xzcsSfxzcj">
										<el-select v-model="ruleFormBJ.xzcsSfxzcj" style="width: 100px;float: left;" @change="ruleFormBJ.xzcsSfxzcj==0 ? ruleFormBJ.xzcsXzcj='' : ruleFormBJ.xzcsXzcj">
											<el-option label="否" :value="0"></el-option>
											<el-option label="是" :value="1"></el-option>
										</el-select>
										<el-input v-model="ruleFormBJ.xzcsXzcj" :disabled="ruleFormBJ.xzcsSfxzcj == 0" placeholder="请输入限制人数" style="width: 200px;float: left;margin-left: 20px;"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<h4 class="title-h4">移送公安</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="" prop="xzcsSfysgaj">
										<span slot="label">移送公安<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.xzcsSfysgaj">
											<el-option label="否" :value="0"></el-option>
											<el-option label="是" :value="1"></el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="移送时间" prop="xzcsYsgajsj">
										<el-date-picker v-model="ruleFormBJ.xzcsYsgajsj" type="date" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
									</el-form-item>
								</el-col>
							</el-row>
							<h4 class="title-h4">行政处罚</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="作出处罚时间" prop="xzcfCfsj">
										<el-date-picker v-model="ruleFormBJ.xzcfCfsj" type="date" value-format="yyyy-MM-dd" @change="xzcfCfsjChange"></el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<p style="text-align: left;padding-left: 20px;line-height: 30px;">
										<el-checkbox v-model="ruleFormBJ.xzcfSfjg" :disabled="ruleFormBJ.xzcfCfsj == '' || ruleFormBJ.xzcfCfsj == null" @change="xzcfSfjgChange">是否警告</el-checkbox>
										<el-input size="mini" v-model="ruleFormBJ.xzcfJgrs" placeholder="请输入限制警告人数" :disabled="ruleFormBJ.xzcfCfsj == '' || ruleFormBJ.xzcfCfsj == null" style="width:50%;margin-left: 10px;"></el-input> 人
									</p>
									<p style="text-align: left;padding-left: 20px;line-height: 30px;">
										<el-checkbox v-model="ruleFormBJ.xzcfSffk" :disabled="ruleFormBJ.xzcfCfsj == '' || ruleFormBJ.xzcfCfsj == null" @change="xzcfSffkChange">是否处罚</el-checkbox>
										<el-input size="mini" v-model="ruleFormBJ.xzcfFk" placeholder="请输入罚款金额" :disabled="ruleFormBJ.xzcfCfsj == '' || ruleFormBJ.xzcfCfsj == null" style="width:50%;margin-left: 10px;"></el-input> 万元
									</p>
									<p style="text-align: left;padding-left: 20px;line-height: 30px;">
										<el-checkbox v-model="ruleFormBJ.xzcfTcty" :disabled="ruleFormBJ.xzcfCfsj == '' || ruleFormBJ.xzcfCfsj == null">是否责令停产停业</el-checkbox>
									</p>
									<p style="text-align: left;padding-left: 20px;line-height: 30px;">
										<el-checkbox v-model="ruleFormBJ.xzcfSfdx" :disabled="ruleFormBJ.xzcfCfsj == '' || ruleFormBJ.xzcfCfsj == null">吊销许可证/营业执照或注册登记证书</el-checkbox>
									</p>
								</el-col>
							</el-row>
							<h4 class="title-h4">资金清退</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="已清退资金金额" prop="xzczYqtje">
										<el-input v-model="ruleFormBJ.xzczYqtje" style="width: 90%;float: left;"></el-input> 万元
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									
								</el-col>
							</el-row>
						</div>
					</el-form>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFormBJ('ruleFormBJ')">确 定</el-button>
			</span>
			<el-dialog
				width="30%"
				center
				title="添加信息"
				:visible.sync="innerDialogVisibleBJ"
				append-to-body>
				<el-form :model="formAdd" :rules="rulesFormAdd" ref="formAdd" label-width="150px" label-position="left" class="demo-ruleForm madal-box-anjian-add">
				  <el-form-item prop="companyName" v-show="formAdd.showName != 2">
						<span slot="label">单位名称<font style="color: #F56C6C;">*</font></span>
				    <el-input v-model="formAdd.companyName" placeholder="请输入单位名称"></el-input>
				  </el-form-item>
				  <el-form-item prop="name">
						<span slot="label">法人或者负责人姓名<font style="color: #F56C6C;">*</font></span>
				    <el-input v-model="formAdd.name" placeholder="请输入法人或者负责人姓名"></el-input>
				  </el-form-item>
				  <el-form-item label="身份证件号码" prop="code">
				    <el-input v-model="formAdd.code" placeholder="请输入身份证件号码"></el-input>
				  </el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="handleFormAddTags">确定</el-button>
				</div>
			</el-dialog>
		</el-dialog>
		
		<!-- 驳回   弹框 -->
		<el-dialog title="驳回原因" :visible.sync="dialogVisibleBH" width="30%" class="madal-box-anjian" :close-on-click-modal="false">
			<div style="width: 100%;">
				<el-form :model="ruleFormBH" ref="ruleFormBH" label-width="0px" class="demo-ruleForm">
					<el-row :gutter="30">
						<el-col :span="24">
							<el-form-item label="" prop="rejectReason">
								<el-input type="textarea" :rows="2" v-model="ruleFormBH.rejectReason"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFormBH('ruleFormBH')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { exportExcel } from '../../provider/index.js' //导出Excel公用方法
export default {
  name: 'case-administrative',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			levelSession:JSON.parse(sessionStorage.getItem("user_xjfj")).level,
			loadingFlag: false,
			pickerOptionsLA: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			pickerOptionsBS: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch:{
				companyName: this.$route.query.name!=undefined ? this.$route.query.name : '',
				timeLA:null,
				timeBS:null,
				uploadStatus: '0',
				eventStatus: '',
				prop: 'updateTime',
				order: '1',
				cityId: this.$route.query.cityId!=undefined ? Number(this.$route.query.cityId) : '',
				cityIdList:[],
			},
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName:"导出所选",
			exportAllName:"导出全部",
			dialogVisibleBJ: false, //是否打开查看弹框
			ruleFormBJ: {
				id: '',
				zeroStatus:0,//是否 零上报
				zeroStatusName:'零上报',
				zeroStatusShow:true,
				//省市区  层级选择器
				quyuList: [], //省市区   数据
				quyuProps: {
					lazy: true,
				},
				industryList: [],//行业   数据
				financingChannelsList: [],//集资渠道   数据
				tagsListQY:[],//企业名称   企业
				tagsListGR:[],//企业名称   个人
				tagsListQT:[],//企业名称   其他
				jbxxQtLsb:'',//企业名称  零上报
				eventStatus:0,//案件类型
				jbxxSshy:'',//行业
				jbxxSshyqt:'',//其他行业
				jbxxJzqd:'',//集资渠道
				jbxxLjjzje:'',//集资金额
				jbxxWdfje:'',//未清退金额
				jbxxLsjzrs:'',//参与集资人数
				jbxxJzrs:'',//未清退人数
				fzczdxzjgssq: [],//行政机关所在地
				xzczFzczdxzjg:'',//负责处置的行政机关所在地
				xzczLasj:'',//立案时间
				xzcsSfxzcj:0,//限制出境
				xzcsXzcj:"",//限制人数
				xzczXzrdjg:0,//行政认定
				xzcsSfysgaj:0,//是否移送公安
				xzcsYsgajsj:'',//移送时间
				xzcfCfsj:'',//作出处罚时间
				xzcfSfjg:false,//是否警告
				xzcfJgrs:'',//警告人数
				xzcfSffk:false,//是否处罚
				xzcfFk:'',//罚款金额
				xzcfTcty:false,//是否责令停产停业
				xzcfSfdx:false,//吊销许可证/营业执照或注册登记证书
				xzczYqtje:'',//已清退资金金额
			},
			rulesFormBJ: {
				eventStatus: [
					{ required: true, message: '请选择案件类型', trigger: 'change' }
				],
				jbxxSshy: [
					{ required: true, message: '请选择行业领域', trigger: 'change' }
				],
				jbxxSshyqt: [
					{ required: true, message: '请输入其他行业', trigger: 'blur' }
				],
				jbxxJzqd: [
					{ required: true, message: '请选择集资渠道', trigger: 'change' }
				],
				jbxxLjjzje: [
					{ required: true, message: '请输入集资金额', trigger: 'blur' },
				],
				jbxxWdfje: [
					{ required: true, message: '请输入未清退金额', trigger: 'blur' },
				],
				jbxxLsjzrs: [
					{ required: true, message: '请输入参与集资人数', trigger: 'blur' },
				],
				jbxxJzrs: [
					{ required: true, message: '请输入未清退人数', trigger: 'blur' },
				],
				fzczdxzjgssq: [
					{ required: true, message: '请选择行政机关所在地', trigger: 'change' },
				],
				xzczFzczdxzjg: [
					{ required: true, message: '请输入负责处置的行政机关所在地', trigger: 'blur' },
				],
				xzczLasj: [
					{ required: true, message: '请选择立案时间', trigger: 'blur' }
				], 
				xzcsSfysgaj: [
					{ required: true, message: '请选择移送公安', trigger: 'change' },
				],
			},
			innerDialogVisibleBJ:false,
			formAdd:{
				companyName:'',
				name:'',
				code:'',
				showName:''
			},
			rulesFormAdd: {
				companyName: [
					{ required: true, message: '请输入单位名称', trigger: 'blur' },
				],
				name: [
					{ required: true, message: '请输入法人或者负责人姓名', trigger: 'blur' },
				],
			},
			dialogVisibleBH: false, //是否打开驳回弹框
			ruleFormBH:{
				id: '',
				rejectReason:''
			}
		};
  },
  mounted() {}, 
  created() {
		this.getCityIdList(); //加载列表
		this.getPageList(); //加载列表
		
		//加载   编辑弹框
		//省市区  层级选择器
		let _this = this; //this指向   重定义
		this.ruleFormBJ.quyuProps.lazyLoad = function lazyLoad(node, resolve) {
			//所对应节点数据
			const { level } = node;
			//获取下一节点数据
			if(level < 2){
				setTimeout(() => {
					_this.$provider.get('/wg-ifrRisk/upload/area', {
						params: {
							parentCode: level == 0 ? 0 : node.value
						}
					}).then(res => {
						const nodes = [];
						for (let i = 0; i < res.data.length; i++) {
							nodes.push({
								label: res.data[i].name,
								value: res.data[i].code,
								leaf: level >= 1
							});
						}
						// 通过调用resolve将子节点数据返回，通知组件数据加载完成
						resolve(nodes);
					})
				}, 500);
			} else {
				node.loading = false;
			}
		}
		this.getIndustryFinancingChannelsList();//加载弹框  行业  集资渠道
  },
  methods: {
		getCityIdList(){
			this.$provider.get('/wg-ifrRisk/upload/area', {
				params: {
					parentCode: 660000
				}
			}).then(res => {
				this.formSearch.cityIdList = res.data;
			})
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		handleClick(tab, event) {
			this.formSearch.uploadStatus = tab.index;
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			let paramStr = this.getParams();
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/upload/searchAdministrationPage',{
				params:{
					name: paramStr.companyName.replace(/^\s+|\s+$/g,""),
					uploadStatus: paramStr.uploadStatus,
					eventStatus: paramStr.eventStatus,
					startTime: paramStr.startTime,
					endTime: paramStr.endTime,
					uploadStartTime: paramStr.uploadStartTime,
					uploadEndTime: paramStr.uploadEndTime,
					cityId:paramStr.cityId,
					orderColumn: paramStr.prop,
					orderDesc: paramStr.order,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				}
			}).then(res=>{
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//排序
		sortChange(column, prop, order) {
			if (column.order == "ascending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "0";
			} else if (column.order == "descending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "1";
			} else {
				this.formSearch.prop = "";
				this.formSearch.order = "";
			}
			this.getPageList(); //加载列表
		}, 
		
		//上报
		handleReport(index,item){
			if(confirm("确定上报本条数据吗？")){
				this.$provider.get('/wg-ifrRisk/upload/uploadAdministration', {
					params: {
						id: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.getPageList();
					}
				})
			}
		},
		//驳回
		handleBack(index,item){
			this.dialogVisibleBH = true;
			this.ruleFormBH.id = item.id;
			this.ruleFormBH.rejectReason = item.rejectReason;
		},
		//驳回  提交
		submitFormBH(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if(confirm("确定驳回本条数据吗？")){
						this.$provider.get('/wg-ifrRisk/upload/rejectAdministration', {
							params: {
								id: this.ruleFormBH.id,
								rejectReason: this.ruleFormBH.rejectReason,
							}
						}).then(res => {
							this.$message({
								message: res.msg,
								type: 'success'
							});
								this.dialogVisibleBH = false; //关闭弹框
							this.getPageList();
						})
					}
				} else {
					console.log('error submit!!');
				}
			});
		},
		
		//新增
		handeleAdd(){
			this.dialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['ruleFormBJ'].clearValidate();
			}) 
			this.ruleFormBJ.zeroStatus = 0;
			this.ruleFormBJ.zeroStatusShow = true;
			this.ruleFormBJ.id = "";
			this.ruleFormBJ.tagsListQY = [];
			this.ruleFormBJ.tagsListGR = [];
			this.ruleFormBJ.tagsListQT = [];
			this.ruleFormBJ.jbxxQtLsb = "";
			this.ruleFormBJ.eventStatus = "";//案件类型
			this.ruleFormBJ.jbxxSshy = "";//行业
			this.ruleFormBJ.jbxxSshyqt = "";//其他行业
			this.ruleFormBJ.jbxxJzqd = "";//集资渠道
			this.ruleFormBJ.jbxxLjjzje = "";//集资金额
			this.ruleFormBJ.jbxxWdfje = "";//未清退金额
			this.ruleFormBJ.jbxxLsjzrs = "";//参与集资人数
			this.ruleFormBJ.jbxxJzrs = "";//未清退人数
			this.ruleFormBJ.fzczdxzjgssq = [];//行政机关所在地
			this.ruleFormBJ.xzczFzczdxzjg = "";//负责处置的行政机关所在地
			this.ruleFormBJ.xzczLasj = "";//立案时间
			this.ruleFormBJ.xzcsSfxzcj = "";//限制出境
			this.ruleFormBJ.xzcsXzcj = "";//限制人数
			this.ruleFormBJ.xzczXzrdjg = "";//行政认定
			this.ruleFormBJ.xzcsSfysgaj = "";//是否移送公安
			this.ruleFormBJ.xzcsYsgajsj = "";//移送时间
			this.ruleFormBJ.xzcfCfsj = "";//作出处罚时间
			this.ruleFormBJ.xzcfSfjg = "";//是否警告
			this.ruleFormBJ.xzcfJgrs = "";//警告人数
			this.ruleFormBJ.xzcfSffk = "";//是否处罚
			this.ruleFormBJ.xzcfFk = "";//罚款金额
			this.ruleFormBJ.xzcfTcty = "";//是否责令停产停业
			this.ruleFormBJ.xzcfSfdx = "";//吊销许可证/营业执照或注册登记证书
			this.ruleFormBJ.xzczYqtje = "";//已清退资金金额
		},
		//编辑 
		handleEdit(index,item){
			this.dialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['ruleFormBJ'].clearValidate();
			}) 
			this.ruleFormBJ.zeroStatusShow = false;
			this.ruleFormBJ.zeroStatus = item.zeroStatus;
			if(item.zeroStatus != 0){
				this.rulesFormBJ = {};
			} else {
				this.rulesFormBJ = {
					eventStatus: [
						{ required: true, message: '请选择案件类型', trigger: 'change' }
					],
					jbxxSshy: [
						{ required: true, message: '请选择行业领域', trigger: 'change' }
					],
					jbxxSshyqt: [
						{ required: true, message: '请输入其他行业', trigger: 'blur' }
					],
					jbxxJzqd: [
						{ required: true, message: '请选择集资渠道', trigger: 'change' }
					],
					jbxxLjjzje: [
						{ required: true, message: '请输入集资金额', trigger: 'blur' },
					],
					jbxxWdfje: [
						{ required: true, message: '请输入未清退金额', trigger: 'blur' },
					],
					jbxxLsjzrs: [
						{ required: true, message: '请输入参与集资人数', trigger: 'blur' },
					],
					jbxxJzrs: [
						{ required: true, message: '请输入未清退人数', trigger: 'blur' },
					],
					fzczdxzjgssq: [
						{ required: true, message: '请选择行政机关所在地', trigger: 'change' },
					],
					xzczFzczdxzjg: [
						{ required: true, message: '请输入负责处置的行政机关所在地', trigger: 'blur' },
					],
					xzczLasj: [
						{ required: true, message: '请选择立案时间', trigger: 'blur' }
					], 
					xzcsSfysgaj: [
						{ required: true, message: '请选择移送公安', trigger: 'change' },
					], 
				};
			}
			this.ruleFormBJ.id = item.id;
			//加载回显数据   企业名称
			this.ruleFormBJ.tagsListQY = [];
			this.ruleFormBJ.tagsListGR = [];
			this.ruleFormBJ.tagsListQT = [];
			this.$provider.get('/wg-ifrRisk/upload/getCaseById',{
				params:{
					id:item.id,
				}  
			}).then(res=>{
				for(var i=0;i<res.data.length;i++){
					if(res.data[i].type == 1){
						this.ruleFormBJ.tagsListQY.push(res.data[i]);
					} else if(res.data[i].type == 2){
						this.ruleFormBJ.tagsListGR.push(res.data[i]);
					} else if(res.data[i].type == 3){
						this.ruleFormBJ.tagsListQT.push(res.data[i]);
						if(item.zeroStatus == 1){
							this.ruleFormBJ.jbxxQtLsb = res.data[0].companyName;
						} else {
							this.ruleFormBJ.jbxxQtLsb = "";
						}
					}
				}
			}) 
			this.ruleFormBJ.eventStatus = item.eventStatus;//案件类型
			this.ruleFormBJ.jbxxSshy = item.jbxxSshy;//行业
			this.ruleFormBJ.jbxxSshyqt = item.jbxxSshyqt;//其他行业
			this.ruleFormBJ.jbxxJzqd = item.jbxxJzqd;//集资渠道
			this.ruleFormBJ.jbxxLjjzje = item.jbxxLjjzje;//集资金额
			this.ruleFormBJ.jbxxWdfje = item.jbxxWdfje;//未清退金额
			this.ruleFormBJ.jbxxLsjzrs = item.jbxxLsjzrs;//参与集资人数
			this.ruleFormBJ.jbxxJzrs = item.jbxxJzrs;//未清退人数
			//行政机关所在地
			let provinceId = item.provinceId!=null ? item.provinceId : "";
			let cityId = item.cityId!=null ? item.cityId : "";
			let areaId = item.areaId!=null ? item.areaId : "";
			this.ruleFormBJ.fzczdxzjgssq = [];
			if(provinceId != null && cityId == null){
				this.ruleFormBJ.fzczdxzjgssq.push(provinceId);
			} else if(provinceId != null && cityId != null){
				this.ruleFormBJ.fzczdxzjgssq.push(provinceId);
				this.ruleFormBJ.fzczdxzjgssq.push(cityId);
			} else if(provinceId != null && cityId != null && areaId != null){
				this.ruleFormBJ.fzczdxzjgssq.push(provinceId);
				this.ruleFormBJ.fzczdxzjgssq.push(cityId);
				this.ruleFormBJ.fzczdxzjgssq.push(areaId);
			}
			this.ruleFormBJ.xzczFzczdxzjg = item.xzczFzczdxzjg;//负责处置的行政机关所在地
			this.ruleFormBJ.xzczLasj = item.xzczLasj;//立案时间
			this.ruleFormBJ.xzcsSfxzcj = item.xzcsSfxzcj;//限制出境
			this.ruleFormBJ.xzcsXzcj = item.xzcsXzcj;//限制人数
			this.ruleFormBJ.xzczXzrdjg = item.xzczXzrdjg;//行政认定
			this.ruleFormBJ.xzcsSfysgaj = item.xzcsSfysgaj;//是否移送公安
			this.ruleFormBJ.xzcsYsgajsj = item.xzcsYsgajsj;//移送时间
			this.ruleFormBJ.xzcfCfsj = item.xzcfCfsj;//作出处罚时间
			this.ruleFormBJ.xzcfSfjg = item.xzcfSfjg == 0 ? false : true;//是否警告
			this.ruleFormBJ.xzcfJgrs = item.xzcfJgrs;//警告人数
			this.ruleFormBJ.xzcfSffk = item.xzcfSffk == 0 ? false : true;//是否处罚
			this.ruleFormBJ.xzcfFk = item.xzcfFk;//罚款金额
			this.ruleFormBJ.xzcfTcty = item.xzcfTcty == 0 ? false : true;//是否责令停产停业
			this.ruleFormBJ.xzcfSfdx = item.xzcfSfdx == 0 ? false : true;//吊销许可证/营业执照或注册登记证书
			this.ruleFormBJ.xzczYqtje = item.xzczYqtje;//已清退资金金额
		},
		//关闭弹框  回调
		closeHandle(){
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['ruleFormBJ'].clearValidate();
			}) 
		}, 
		//确定其他行业   校验与显隐
		jbxxSshyChange(){
			if(this.ruleFormBJ.jbxxSshy == '其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）'){
				this.ruleFormBJ.jbxxSshyqt='';
				this.rulesFormBJ.jbxxSshyqt[0].required=true;
			} else {
				this.ruleFormBJ.jbxxSshyqt='';
				this.rulesFormBJ.jbxxSshyqt[0].required=false;
			}
		},
		//零上报
		zeroStatusHandle(){ 
			if(this.ruleFormBJ.zeroStatus == 0){
				this.ruleFormBJ.zeroStatusName = "正常上报";
				this.ruleFormBJ.zeroStatus = 1;
				this.ruleFormBJ.jbxxQtLsb = this.getDate() + "行政案件零上报";
				this.rulesFormBJ = {};
			} else if(this.ruleFormBJ.zeroStatus == 1){
				this.ruleFormBJ.zeroStatusName = "零上报";
				this.ruleFormBJ.zeroStatus = 0;
				this.ruleFormBJ.tagsListQT = [];
				this.ruleFormBJ.jbxxQtLsb = "";
				this.rulesFormBJ = {
					eventStatus: [
						{ required: true, message: '请选择案件类型', trigger: 'change' }
					],
					jbxxSshy: [
						{ required: true, message: '请选择行业领域', trigger: 'change' }
					],
					jbxxSshyqt: [
						{ required: true, message: '请输入其他行业', trigger: 'blur' }
					],
					jbxxJzqd: [
						{ required: true, message: '请选择集资渠道', trigger: 'change' }
					],
					jbxxLjjzje: [
						{ required: true, message: '请输入集资金额', trigger: 'blur' },
					],
					jbxxWdfje: [
						{ required: true, message: '请输入未清退金额', trigger: 'blur' },
					],
					jbxxLsjzrs: [
						{ required: true, message: '请输入参与集资人数', trigger: 'blur' },
					],
					jbxxJzrs: [
						{ required: true, message: '请输入未清退人数', trigger: 'blur' },
					],
					fzczdxzjgssq: [
						{ required: true, message: '请选择行政机关所在地', trigger: 'change' },
					],
					xzczFzczdxzjg: [
						{ required: true, message: '请输入负责处置的行政机关所在地', trigger: 'blur' },
					],
					xzczLasj: [
						{ required: true, message: '请选择立案时间', trigger: 'blur' }
					], 
					xzcsSfysgaj: [
						{ required: true, message: '请选择移送公安', trigger: 'change' },
					],
				};
				//清除  表单验证
				this.$nextTick(() => {
					this.$refs['ruleFormBJ'].clearValidate();
				})
			}
		},
		getDate(){
			var date = new Date();
			var year=date.getFullYear();
			var month = date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
			return year + "年" + month + "月";
		},
		//编辑  提交
		submitFormBJ(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if(this.ruleFormBJ.zeroStatus == 0){
						if(this.$refs['quyuRef'].getCheckedNodes()[0] == null){
							this.$message({
								message: '行政机关所在地不能为空，请选择',
								type: 'warning'
							});
						} else { 
							let quyuName = this.$refs['quyuRef'].getCheckedNodes()[0].pathLabels;
							let administrative = {
								id : this.ruleFormBJ.id,
								jbxxSshy : this.ruleFormBJ.jbxxSshy,
								jbxxSshyqt : this.ruleFormBJ.jbxxSshy=='其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）' ? this.ruleFormBJ.jbxxSshyqt : '',//其他行业
								jbxxJzqd : this.ruleFormBJ.jbxxJzqd,
								jbxxLjjzje : this.ruleFormBJ.jbxxLjjzje,
								jbxxWdfje : this.ruleFormBJ.jbxxWdfje,
								jbxxLsjzrs : this.ruleFormBJ.jbxxLsjzrs,
								jbxxJzrs : this.ruleFormBJ.jbxxJzrs,
								provinceName : quyuName.length >= 1 ? quyuName[0] : "",
								cityName : quyuName.length >= 2 ? quyuName[1] : "",
								areaName : quyuName.length >= 3 ? quyuName[2] : "",
								provinceId : this.ruleFormBJ.fzczdxzjgssq.length >= 1 ? this.ruleFormBJ.fzczdxzjgssq[0] : "",
								cityId :	this.ruleFormBJ.fzczdxzjgssq.length >= 2 ? this.ruleFormBJ.fzczdxzjgssq[1] : "",
								areaId : this.ruleFormBJ.fzczdxzjgssq.length >= 3 ? this.ruleFormBJ.fzczdxzjgssq[2] : "",
								zeroStatus : this.ruleFormBJ.zeroStatus,
								eventStatus : this.ruleFormBJ.eventStatus,
								xzczFzczdxzjg : this.ruleFormBJ.xzczFzczdxzjg,
								xzczLasj : this.ruleFormBJ.xzczLasj,
								xzcsSfxzcj : this.ruleFormBJ.xzcsSfxzcj,
								xzcsXzcj : this.ruleFormBJ.xzcsXzcj,
								xzczXzrdjg : this.ruleFormBJ.xzczXzrdjg,
								xzcsSfysgaj : this.ruleFormBJ.xzcsSfysgaj,
								xzcsYsgajsj : this.ruleFormBJ.xzcsYsgajsj,
								xzcfCfsj : this.ruleFormBJ.xzcfCfsj,
								xzcfSfjg : this.ruleFormBJ.xzcfSfjg == false ? 0 : 1,
								xzcfJgrs : this.ruleFormBJ.xzcfJgrs,
								xzcfSffk : this.ruleFormBJ.xzcfSffk == false ? 0 : 1,
								xzcfFk : this.ruleFormBJ.xzcfFk,
								xzcfTcty : this.ruleFormBJ.xzcfTcty == false ? 0 : 1,
								xzcfSfdx : this.ruleFormBJ.xzcfSfdx == false ? 0 : 1,
								xzczYqtje : this.ruleFormBJ.xzczYqtje,
							}
							let uploadCaseList = [];
							let uploadCaseList1 = uploadCaseList.concat(this.ruleFormBJ.tagsListQY);
							let uploadCaseList2 = uploadCaseList1.concat(this.ruleFormBJ.tagsListGR);
							let uploadCaseList3 = uploadCaseList2.concat(this.ruleFormBJ.tagsListQT);
							this.$provider.post('/wg-ifrRisk/upload/updateAdministration', {
								administrative:administrative,
								uploadCaseList:uploadCaseList3
							}).then(res => {
								if (res.code == 200) {
									if(this.ruleFormBJ.id == ""){
										this.$message({
											message: '新增成功',
											type: 'success'
										});
									} else {
										this.$message({
											message: '编辑成功',
											type: 'success'
										});
									}
									this.dialogVisibleBJ = false; //关闭弹框
									this.getPageList(); //加载列表
								}
							}) 
						} 
					} else {
						let administrative = {
							id : this.ruleFormBJ.id,
							jbxxSshy : '',
							jbxxSshyqt : '',//其他行业
							jbxxJzqd : '',
							jbxxLjjzje : '',
							jbxxWdfje : '',
							jbxxLsjzrs :'',
							jbxxJzrs : '',
							provinceName : "",
							cityName : "",
							areaName : "",
							provinceId : "",
							cityId : "",
							areaId : "",
							zeroStatus : this.ruleFormBJ.zeroStatus,
							eventStatus : '',
							xzczFzczdxzjg : '',
							xzczLasj : '',
							xzcsSfxzcj : '',
							xzcsXzcj : '',
							xzczXzrdjg : '',
							xzcsSfysgaj : '',
							xzcsYsgajsj : '',
							xzcfCfsj : '',
							xzcfSfjg : '',
							xzcfJgrs : '',
							xzcfSffk : '',
							xzcfFk : '',
							xzcfTcty : '',
							xzcfSfdx : '',
							xzczYqtje : '',
						};
						let zeroData = [
							{
								companyName: this.ruleFormBJ.jbxxQtLsb,
								personName: "",
								personZjhm: "",
								type: 3
							}
						];
						let uploadCaseList = [];
						let uploadCaseList1 = uploadCaseList.concat([]);
						let uploadCaseList2 = uploadCaseList1.concat([]);
						let uploadCaseList3 = uploadCaseList2.concat(zeroData);
						this.$provider.post('/wg-ifrRisk/upload/updateAdministration', {
							administrative:administrative,
							uploadCaseList:uploadCaseList3
						}).then(res => {
							if (res.code == 200) {
								if(this.ruleFormBJ.id == ""){
									this.$message({
										message: '新增成功',
										type: 'success'
									});
								} else {
									this.$message({
										message: '编辑成功',
										type: 'success'
									});
								}
								this.dialogVisibleBJ = false; //关闭弹框
								this.getPageList(); //加载列表
							}
						}) 
					}
				} else {
					console.log('error submit!!');
				}
			});
		},
		//加载弹框  行业  集资渠道
		getIndustryFinancingChannelsList() {
			this.$provider.get('/wg-ifrRisk/upload/dict',{}).then(res=>{
				this.ruleFormBJ.industryList = res.data.industry;
				this.ruleFormBJ.financingChannelsList = res.data.financingChannels;
			})
		},
		//企业名称   标签 可移出
		handleCloseTagsQY(tag) {
			this.ruleFormBJ.tagsListQY.splice(this.ruleFormBJ.tagsListQY.indexOf(tag), 1);
		},
		handleClickTagsQY(tag,index){
			this.innerDialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['formAdd'].clearValidate();
			}) 
			this.formAdd.formAddIndex = index;
			this.formAdd.showName = tag.type;
			this.formAdd.companyName = tag.companyName;
			this.formAdd.name = tag.personName;
			this.formAdd.code = tag.personZjhm;
		},
		handleCloseTagsGR(tag) {
			this.ruleFormBJ.tagsListGR.splice(this.ruleFormBJ.tagsListGR.indexOf(tag), 1);
		},
		handleClickTagsGR(tag,index){
			this.innerDialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['formAdd'].clearValidate();
			}) 
			this.formAdd.formAddIndex = index;
			this.formAdd.showName = tag.type;
			this.formAdd.companyName = tag.companyName;
			this.formAdd.name = tag.personName;
			this.formAdd.code = tag.personZjhm;
		},
		handleCloseTagsQT(tag) {
			this.ruleFormBJ.tagsListQT.splice(this.ruleFormBJ.tagsListQT.indexOf(tag), 1);
		},
		handleClickTagsQT(tag,index){
			this.innerDialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['formAdd'].clearValidate();
			}) 
			this.formAdd.formAddIndex = index;
			this.formAdd.showName = tag.type;
			this.formAdd.companyName = tag.companyName;
			this.formAdd.name = tag.personName;
			this.formAdd.code = tag.personZjhm;
		},
		//企业名称   标签 可添加
		handleAddTags(type){
			this.innerDialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['formAdd'].clearValidate();
			}) 
			this.formAdd.formAddIndex = "-1";
			this.formAdd.showName = type;
			this.formAdd.companyName = '';
			this.formAdd.name = '';
			this.formAdd.code = '';
		},
		//企业名称  标签  添加事件
		handleFormAddTags(){
			if(this.formAdd.showName == 2){
				if(this.formAdd.name == ""){
					this.$message({
						message: '法人或者负责人姓名不能为空',
						type: 'warning'
					});
				} else {
					let obj = {
						companyName: this.formAdd.companyName,
						personName: this.formAdd.name,
						personZjhm: this.formAdd.code,
						type: this.formAdd.showName
					}
					if(this.formAdd.showName == 1){
						if(this.formAdd.formAddIndex == "-1"){
							this.ruleFormBJ.tagsListQY.push(obj);
						} else {
							this.ruleFormBJ.tagsListQY[this.formAdd.formAddIndex] = obj;
						}
					} else if(this.formAdd.showName == 2){
						if(this.formAdd.formAddIndex == "-1"){
							this.ruleFormBJ.tagsListGR.push(obj);
						} else {
							this.ruleFormBJ.tagsListGR[this.formAdd.formAddIndex] = obj;
						}
					} else if(this.formAdd.showName == 3){
						if(this.formAdd.formAddIndex == "-1"){
							this.ruleFormBJ.tagsListQT.push(obj);
						} else {
							this.ruleFormBJ.tagsListQT[this.formAdd.formAddIndex] = obj;
						}
					}
					this.innerDialogVisibleBJ = false; //关闭弹框
				}
			} else {
				if(this.formAdd.companyName == ""){
					this.$message({
						message: '单位名称不能为空',
						type: 'warning'
					});
				} else if(this.formAdd.name == ""){
					this.$message({
						message: '法人或者负责人姓名不能为空',
						type: 'warning'
					});
				} else {
					let obj = {
						companyName: this.formAdd.companyName,
						personName: this.formAdd.name,
						personZjhm: this.formAdd.code,
						type: this.formAdd.showName
					}
					if(this.formAdd.showName == 1){
						if(this.formAdd.formAddIndex == "-1"){
							this.ruleFormBJ.tagsListQY.push(obj);
						} else {
							this.ruleFormBJ.tagsListQY[this.formAdd.formAddIndex] = obj;
						}
					} else if(this.formAdd.showName == 2){
						if(this.formAdd.formAddIndex == "-1"){
							this.ruleFormBJ.tagsListGR.push(obj);
						} else {
							this.ruleFormBJ.tagsListGR[this.formAdd.formAddIndex] = obj;
						}
					} else if(this.formAdd.showName == 3){
						if(this.formAdd.formAddIndex == "-1"){
							this.ruleFormBJ.tagsListQT.push(obj);
						} else {
							this.ruleFormBJ.tagsListQT[this.formAdd.formAddIndex] = obj;
						}
					}
					this.innerDialogVisibleBJ = false; //关闭弹框
				}
			}
		},
		//处罚时间 改变
		xzcfCfsjChange(item){
			if(item == null){
				this.ruleFormBJ.xzcfSfjg = false;//是否警告
				this.ruleFormBJ.xzcfJgrs = '';//警告人数
				this.ruleFormBJ.xzcfSffk = false;//是否处罚
				this.ruleFormBJ.xzcfFk = '';//罚款金额
				this.ruleFormBJ.xzcfTcty = false;//是否责令停产停业
				this.ruleFormBJ.xzcfSfdx = false;//吊销许可证/营业执照或注册登记证书
			}
		},
		xzcfSfjgChange(item){
			if(item == false){
				this.ruleFormBJ.xzcfJgrs = '';//警告人数
			}
		},
		xzcfSffkChange(item){
			if(item == false){
				this.ruleFormBJ.xzcfFk = '';//罚款金额
			}
		},
		
		//表格 每行  复选框选值    导出  删除
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//删除所选
		handeleDelete() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				this.$provider.get('/wg-ifrRisk/upload/deleteAdministration', {
					params: {
						id: chk_value,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getPageList();
					}
				})
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
			}
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr+'/wg-ifrRisk/upload/exportAdministrativeByIds',
					fileName: '行政案件',
					params: 'ids='+chk_value
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj,this);// 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
			}
		},
		//导出全部
		exportALLFn() {
			let paramStr = this.getParams();
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr+'/wg-ifrRisk/upload/exportAdministrativeAll',
				fileName: '行政案件',
				params: 'name='+paramStr.companyName+'&uploadStatus='+paramStr.uploadStatus+'&eventStatus='+paramStr.eventStatus+'&cityId='+paramStr.cityId+'&startTime='+paramStr.startTime+'&endTime='+paramStr.endTime+'&uploadStartTime='+paramStr.uploadStartTime+'&uploadEndTime='+paramStr.uploadEndTime
			} 
			this.exportAllName = "正在导出中...";
			exportExcel(myObj,this);// 导出Excel公用方法
		},
		
		//获取参数
		getParams(){
			let obj = {
				companyName: this.formSearch.companyName,
				uploadStatus:this.formSearch.uploadStatus,
				eventStatus:this.formSearch.eventStatus,
				startTime: this.formSearch.timeLA != null ? this.formSearch.timeLA[0] : "",
				endTime: this.formSearch.timeLA != null ? this.formSearch.timeLA[1] : "",
				uploadStartTime: this.formSearch.timeBS != null ? this.formSearch.timeBS[0] : "",
				uploadEndTime: this.formSearch.timeBS != null ? this.formSearch.timeBS[1] : "",
				cityId: this.formSearch.cityId,
				prop: this.formSearch.prop,
				order: this.formSearch.order,
			}
			return obj;
		}
  }
};
</script>

<style lang="stylus" scoped="scoped"></style>
